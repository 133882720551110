import React from 'react'
import { useParams } from "react-router-dom";
const MaintenancePage = () => {
    const { lang } = useParams()
    console.log(lang, 'maintenance')

    return (
        <div className='container'>
            <div className='row justify-content-center align-items-center min-h-100'>
                <div className='col-md-6 text-center'>
                    <img src="/images/maintenance.png" alt='Under Construction' className='img-fluid' />
                    {lang === "fr" ? (
                        <>
                            <h1 className='mt-5'>Mise à jour du système</h1>
                            <p>Notre site Web est actuellement en maintenance programmée.</p>
                            <p>
                                Nous devrions revenir sous peu. Merci pour votre patience.</p>
                        </>
                    ) : (
                        <>
                            <h1 className='mt-5'>System Update</h1>
                            <p>Our website is currently undergoing scheduled maintenance.</p>
                            <p>We should be back shortly. Thank you for your patience.</p>
                        </>
                    )}

                </div>
            </div>
        </div>
    )
}

export default MaintenancePage