// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Oxygen&family=Roboto:wght@400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Oxygen", sans-serif !important;
  font-family: "Roboto", sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
iframe#webpack-dev-server-client-overlay {
  display: none !important;
  opacity: 0 !important;
  width: 0px !important;
  height: 0px !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,4CAA4C;EAC5C,4CAA4C;;EAE5C,mCAAmC;EACnC,kCAAkC;AACpC;AACA;EACE,wBAAwB;EACxB,qBAAqB;EACrB,qBAAqB;EACrB,sBAAsB;AACxB;AACA;EACE;aACW;AACb","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Oxygen&family=Roboto:wght@400;500&display=swap\");\n\nbody {\n  margin: 0;\n  font-family: \"Oxygen\", sans-serif !important;\n  font-family: \"Roboto\", sans-serif !important;\n\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\niframe#webpack-dev-server-client-overlay {\n  display: none !important;\n  opacity: 0 !important;\n  width: 0px !important;\n  height: 0px !important;\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
