import { create } from 'zustand';

const useConversionProgressStore = create((set) => ({
    isConverting: false,
    conversionProgress: 0,
    setIsConverting: (status) => set({ isConverting: status }),
    setConversionProgress: (value) => set({ conversionProgress: value }),
    reset: () => set({ isConverting: false, conversionProgress: 0 }),
}));

export default useConversionProgressStore;
