import React, { useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Display from "./Display";
//import axios from 'axios';
import ApiLinks from "../api/apis"
var hostUrl = ApiLinks.ActiveLink();

const Downloadable = ({ download, arr, name }) => {
    let url = hostUrl + "public/catalogs/";
    let file = "test";

    for (const item of arr) {

        if (name === item.catalogname) {
            file = item.filename;
        }
    }

    switch (download) {
        case "done": case "cleaned up": case "compressed": case "finish":
            return <a className="btn bg-yellow w-100 text-white mt-3" href={url + file + ".pdf"} target="_blank" rel="noreferrer">View / Download PDF</a>
            break;
        case "processing":
            return <button className="btn btn-primary button-fulls w-100 mt-3" ><i className="bi bi-arrow-clockwise"></i> Processing</button>
            break;
        case "7 Days Deleted":
            return <button className="btn btn-primary button-fulls w-100 mt-3" ><i className="bi bi-arrow-clockwise"></i>DELETED</button>
            break;
        default:
            return <button className="btn btn-secondary button-fulls w-100 mt-3"><i className="bi bi-clock"></i> Pending</button>
    }
};
const DisplaysImahe = ({ arr, name }) => {
    let file = "";
    for (const item of arr) {

        if (name === item.catalogname) {
            if (typeof item.imahe !== 'undefined') {
                file = item.imahe;
            }

            if (file.length < 1) {
                file = "/images/lookbooks/" + name + ".jpg";
            }
        }
    }

    return <img className="card-img-top-download" src={file} alt={name} />
};



const ShowCatalogEn = () => {
    const { catalogListID } = useParams();
    const url = `${hostUrl}saveCatalog/${catalogListID}`;

    const [catalogs, setCatalogs] = useState(null);

    const [isCatalogNull, setIsCatalog] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        // Fetch catalog data from the URL
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 404) {
                    navigate("/404")
                }

                // Get the stored catalog from sessionStorage
                const storedCatalog = JSON.parse(sessionStorage.getItem('Catalog'));

                if (storedCatalog && storedCatalog._id === data._id) {
                    // If the stored catalog matches the fetched one, use the stored one
                    setCatalogs(storedCatalog);
                    sessionStorage.removeItem('Catalog');
                } else {
                    // If not, store the fetched catalog and use it
                    setCatalogs(data);
                    sessionStorage.setItem('Catalog', JSON.stringify(data));
                    sessionStorage.setItem('CatalogIDNew', JSON.stringify(data))
                }
            })
            .catch((error) => {
                // Handle any errors that may occur during the fetch
                console.error('Fetch error:', error);
                setIsCatalog(true)
            });
    }, []);


    return (
        <>
            {catalogs ? (
                <section className={`container position-relative overflow-hidden download ${isCatalogNull ? 'deleted' : ''}`}>
                    <div>
                        {isCatalogNull ? (
                            <p className="desc text-center">Your catalogs are now expired!</p>
                        ) : (
                            <p className="desc text-center">Your catalogs are now ready!</p>
                        )}
                    </div>
                    <div className="d-flex gap-3 justify-content-center align-items-center flex-column flex-md-row">
                        {catalogs?.lookbooks.map((catalog) => (
                            <div key={catalog} className="card-custom mt-4 d-flex justify-content-center align-items-center flex-column">
                                <DisplaysImahe arr={catalogs.output} name={catalog} />
                                <div className="card-body">
                                    <h5 className="card-title text-center">
                                        <Display title={catalog} />
                                    </h5>
                                    <Downloadable download={catalogs.status} arr={catalogs.output} name={catalog} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="disclaimer-cont">
                        {isCatalogNull ? (
                            <>
                                <div className="d-flex justify-content-center align-items-center flex-column h-50">
                                    <p className="gray">This link has already expired. You can click the button below to submit a request again</p>
                                    <a href="/" className="download next-btn">
                                        Submit a request again
                                    </a>
                                </div>
                            </>
                        ) : (
                            <p className="disclaimer-text gray text-center">
                                Please note that this transfer <span className="yellow">link will expire after 30 days</span>. When this happens, these files will be removed from our servers.
                            </p>
                        )}
                    </div>
                </section>
            ) : (
                <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: "500px" }}>
                    <p>Loading . . .</p>
                </div>

            )}

        </>
    );
};

export default ShowCatalogEn;