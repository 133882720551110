//UAT set
const ProductLink = () => {
    return "https://lookbook.decdoutmerch.com/api/"
}
const UATLink = () => {
    return "http://20.123.82.238:5000/";
}
const LocalLink = () => {
    return "http://localhost:5000/";
}
const ActiveLink = () => {
    return ProductLink(); //LocalLink(); //ProductLink();
}
const AnalyticsLink = () => {
    return "https://03ed-40-113-34-8.ngrok-free.app/"
}

export default {
    ProductLink,
    UATLink,
    LocalLink,
    ActiveLink,
    AnalyticsLink,
};