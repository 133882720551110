import React, { useMemo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Dropzone, { useDropzone } from 'react-dropzone';
/*import $ from 'jquery';

$(".image-checkbox").each(function () {
  if ($(this).find('input[type="checkbox"]').first().attr("checked")) {
    $(this).addClass('image-checkbox-checked');
  }
  else {
    $(this).removeClass('image-checkbox-checked');
  }
});

// sync the state to the input
$(".image-checkbox").on("click", function (e) {
  $(this).toggleClass('image-checkbox-checked');
  var $checkbox = $(this).find('input[type="checkbox"]');
  $checkbox.prop("checked", !$checkbox.prop("checked"))

  e.preventDefault();
});
*/
const hostUrl = "http://20.123.82.238:5000/";//"http://localhost:5000/"; //
var stado = [];

const SelectCatalog = () => {
  var [files, setFiles] = useState([]);
  const { catalogName } = useParams();
  var catName = catalogName;

  /*
  const [selectedImages, setSelectedImages] = useState({
      pages: []
  });
  */
  /*
  //if(sessionStorage.getItem("Images")){
  //let tempos = JSON.parse(sessionStorage.getItem("Images"));
  //console.log(tempos.length);
  const [checkedState, setCheckedState] = useState(
      new Array(62).fill(false)
  );

  const handleOnChange = (position) =>{
      console.log("changed position " + position);
      const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : item
      );

      setCheckedState(updatedCheckedState);
  }
  */

  function Basic(props) {

    const {
      getRootProps,
      getInputProps,
      acceptedFiles,
      open
    } = useDropzone({
      accept: {
        "image/png": ['.png']
      },
      noClick: true,
      noKeyboard: true,
      onDrop: acceptedFiles => {
        setFiles(
          acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        );
      }
    });

    const thumbs = files.map(file => (
      <div className="p-2" key={file.name}>
        <img src={file.preview} className="img" />
      </div>
    ));

    useEffect(
      () => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
      },
      //[files]
    );

    return (
      <section>
        <div className="d-flex justify-content-center">
          <div {...getRootProps({})}>
            <input {...getInputProps()} name="fileupload" />

            <svg onClick={open} className="svgcloud" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 266.51 201.7" ><g><path d="M7284.58-7216c0-.84.13-1.68.13-2.52,0-45.52-30.82-82.36-68.84-82.36-27.43,0-51,19.19-62.06,46.92a31.1,31.1,0,0,0-16-4.55c-17.54,0-32.18,15.34-35,35.37-21.06,8.54-36.1,32.28-36.1,60.23,0,35.16,23.85,63.73,53.25,63.73h61v-56h-28.69l47.73-58.62,47.73,58.55H7219v56h65.63c26.89,0,48.6-26.26,48.6-58.41S7311.48-7215.84,7284.58-7216Z" transform="translate(-7066.75 7300.85)"></path></g></svg>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <aside className="d-flex flex-row">{thumbs}</aside>
        </div>
      </section>
    );
  }



  function GetImages(props) {
    //const { catalogName } = useParams();
    let activeCatalog = catalogName;
    if (!activeCatalog) {
      activeCatalog = "Jan23"
    }
    let url = hostUrl + "lookBook/" + activeCatalog;
    catName = activeCatalog;
    console.log(url);

    let statwa = [];
    if (sessionStorage.getItem("Images")) {
      statwa = JSON.parse(sessionStorage.getItem("Images"));
    }

    let [images, setImages] = useState(statwa);

    useEffect(() => {
      fetch(url)
        .then(res => res.json())
        .then(data => {
          //console.log(data);
          if (sessionStorage.getItem("Images")) {
            console.log("session available");
            setImages(data.images);
          } else {
            sessionStorage.setItem("Images", JSON.stringify(data.images));
            setImages(data.images);
            window.location.reload(false);
            console.log("newInstance");
          }
        });
    }, []);

    //console.log(images);
    const [checkedState, setCheckedState] = useState(
      new Array(images.length).fill(false)
    );


    const handleOnChange = (position) => {
      const updatedCheckedState = checkedState.map((item, index) => {
        //index === position ? !item : item
        console.log(item)
        if (index === position) {
          console.log("change value " + item);
          if (!item) {
            console.log("true value original " + item + " new value " + !item + " image: " + images[index]);
            stado.indexOf(images[index]) === -1 ? stado.push(images[index]) : console.log("This item already exists");
          } else {
            console.log("false value original " + item + " new value " + !item + " image: " + images[index]);
            //stado.indexOf(images[index]) === -1 ? console.log("This item not exists") : stado.splice(index,1);
            let SIndex = stado.indexOf(images[index]);
            if (SIndex != -1) {
              stado.splice(SIndex, 1);
            }
          }
          return !item;
        } else {
          console.log("don nothing");
          return item;
        }

      });

      setCheckedState(updatedCheckedState);
      //console.log("has change ==============================" + position + " state " + checkedState[position]);
      //stado = checkedState;
    }

    if (images.length) {
      console.log("content");
      console.log(images.length);

      const resultas = images.map((image, index) => {
        return <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 lookbook-item nopad">
          <label className="image-checkbox">
            {image}
            <img className="card-img-top" src={"/images/lookbooks/" + activeCatalog + "/" + image + ".jpg"} alt={"\"" + image + ".jpg\""} />
            <input key={index} className="form-check-input"
              type="checkbox"
              value={image}
              id={`selected-images-${index}`}
              name="selected[]"
              checked={checkedState[index]}
              onChange={() => handleOnChange(index)}
            />
          </label>
        </div>

      });

      /*old
      const resultas = images.map((image, index) => {
          return <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 lookbook-item">
          <div className="card-group lookbook-card-group">  
            <div variant="dark" className="card lookbook-card">
              <img className="card-img-top" src={"/images/lookbooks/"+activeCatalog+"/"+image+".jpg"} alt={"\""+image+".jpg\""}/><div className="card-img-overlay">
            </div>
            
            <input key={index} className="form-check-input"
                  type="checkbox"
                  value={image}
                  id={`selected-images-${index}`}
                  name="pages"
                  checked={checkedState[index]}
                  onChange = {()=>handleOnChange(index)}
              />
              <label className="form-check-label">{image}
              
              </label>

            </div>
            
          </div>
        </div>
        
          });
      old*/
      /*
      const resultas = images.map((image, index) => {
          return <div key={index} className="col-xs-4 col-sm-3 col-md-2 nopad text-center lookbook-item">
              <label className="image-checkbox">
                  <img className="img-card-img-top" src={"/images/lookbooks/"+activeCatalog+"/"+image+".jpg"} alt={"\""+image+".jpg\""} />
                  <input key={index} className="form-check-input"
                  type="checkbox"
                  value={image}
                  id={`selected-images-${index}`}
                  name="image[]"
                  checked={checkedState[index]}
                  onChange = {()=>handleOnChange(index)}
              />
              </label>
          </div>
        
          });
      */
      return (
        <div className="lookbook-list justify-content-md-center row">{resultas}</div>
      );

    } else {
      console.log("empty");
      console.log(images);
    }

  };

  const onSubmit = (event) => {
    event.preventDefault();
    console.log('submit');
    //console.log(event.target.email.value);
    //console.log(event.target.name.value);
    //console.log(event.target.phone.value);
    //console.log(files);

    //let temp = files.dataURL();
    //console.log(files[0]);
    //console.log(postData);
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = function () {
      var base64data = reader.result;
      console.log(stado);
      //console.log(base64data);

      fetch(hostUrl + `images/upload`, {
        method: 'POST',
        headers: {
          'Content-Type': "text/json"
        },
        body: JSON.stringify({
          "name": event.target.name.value,
          "email": event.target.email.value,
          "lookbooks": ['Jan23'],
          "selected": stado,
          "fileupload": base64data
        })
      })
        .then(res => res.json())
        .then(data => {
          //console.log(data.res_id);
          //window.location.href = '/Catalog/' + data.res_id;
          alert("Processing; Please wait for the email for the download link!");
          window.location.reload(false);
        });

    }

  }

  return (
    <div className="container">
      <form onSubmit={onSubmit}>
        <div className="row">

          <h6 className="text-center mt-4">Step 1:</h6>
          <h5 className="text-center mt-0">upload your company logo</h5>
          <Basic />

        </div>
        <div className="row mt-4">
          <h6 className="text-center mt-4">Step 2:</h6>
          <h5 className="text-center mt-0">select one or multiple images to add your branding for your special lookbook</h5>
          <hr />
          <div className="catalog-head text-center mt-4">
            {catName} Lookbook
          </div>
          <div className="form-group text-center"><GetImages /></div>



        </div>
        <div className="row mt-4">
          <h6 className="text-center mt-4">Step 3:</h6>
          <h5 className="text-center mt-0 mb-4">Enter your information and your custom lookbook will be emailed to you!</h5>
          <label className="lbl-text text-center" for="email">Email Address</label>
          <div className="form-group text-center">
            <input placeholder="name@example.com" type="email" name="email" className="input-text" required></input>
          </div>
          <label className="lbl-text text-center" for="name">Name</label>
          <div className="form-group text-center">
            <input placeholder="Your Name" type="text" name="name" className="input-text" required></input>
          </div>

          <div className="form-group text-center mt-4">
            <button type="submit" className="btn btn-outline-primary">CUSTOMIZE</button>
          </div>
        </div>
      </form>
    </div>
  )
};

export default SelectCatalog;