import React, { useState, useContext, useEffect } from "react";
import Help from "./assets/Help";
import Tutorial from "./assets/Tutorial";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from './i18n';
import LocaleContext from "./context/LocaleContext";
import { useTranslation } from "react-i18next";
function Header() {
  const country = "";
  // const [selectedLabel, setSelectedLabel] = useState("");
  const { locale, setLocale } = useContext(LocaleContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation()
  const isFrenchPathDownload = location.pathname.startsWith("/Catalog/fr") || location.pathname.startsWith("/Catalog") || location.pathname.startsWith("/Catalog/en");
  useEffect(() => {
    // Check if the user manually typed a URL
    const lowerCasePath = location.pathname.toLowerCase();

    switch (lowerCasePath) {
      case "/en" || "/EN":
        setLocale('en');
        i18n.changeLanguage('en');
        break;
      case "/us" || "/US":
        setLocale('us');
        i18n.changeLanguage('us');
        break;
      case "/fr" || "/FR":
        setLocale('fr');
        i18n.changeLanguage('fr');
        break;
      default:
        // Handle the default case here
        break;
    }
  }, [location]);

  const handleChange = (e) => {
    const newLocale = e.target.value;
    const country = e.target.options[e.target.selectedIndex].getAttribute("data-country");
    console.log(newLocale, 'see?')
    i18n.changeLanguage(newLocale);

    if (country === "Canada-FR") {
      e.preventDefault();
      navigate('fr');
    } else {
      navigate(`/${newLocale}`);
    }

    window.location.reload();
  }


  return (
    <>
      {isFrenchPathDownload ? (null) : (
        <section className="topbar-wp">
          <div className="container">
            <div className="d-flex justify-content-end align-items-center pt-2">
              {/* <div className="">
                <span className="text-white">
                  {t("Home.noted")}</span>
              </div> */}
              <Form.Select aria-label="Default select example" value={locale} onChange={handleChange} size="sm" style={{ width: 250 }}>
                <option value="us" data-country="United States">United States</option>
                <option value="fr" data-country="Canada-FR">Canada-FR</option>
                <option value="en" data-country="Canada-EN">Canada-EN</option>
              </Form.Select>
            </div >
          </div >
        </section >
      )}

      <header className="p-2">
        <div className="container">
          <div className="d-flex align-items-center space-between">
            <a href="/" className="logo">
              <img width={150} alt="catalog logo" src="/images/logo-alt.png" className="App-logo2" />
            </a>
            <div className="d-flex align-items-center">
              <Help />
              <Tutorial />
            </div>
          </div>
        </div>
      </header>


    </>
  );
}

export default Header;
