import ReactSlider from "react-slider";
import "./slider.css";
const Slider = ({ onChange, currentIndex, spanIndex }) => {
  return (
    <>
      <ReactSlider
        className="horizontal-slider"
        marks
        markClassName={`example-mark`}
        min={0}
        max={3}
        onChange={onChange}
        thumbClassName="example-thumb"
        trackClassName="example-track"
        renderMark={(props) => {
          if (props.key < currentIndex) {
            props.className = `example-mark example-mark-completed span-${props.key += 1}`;
          } else if (props.key === currentIndex) {
            props.className = `example-mark example-mark-active span-${props.key += 1}`;
          }
          else {
            props.className = `example-mark span-${props.key += 1}`
          }
          return <span {...props} />;
        }}

      />

    </>
  );
};

export default Slider;