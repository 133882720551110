import React, { useState, useEffect } from "react";
import Modals from "./Modals";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const Help = () => {
    const { t } = useTranslation()
    const [show, setShow] = useState(false)
    const location = useLocation();


    useEffect(() => {
        const hasVisitedBefore = localStorage.getItem('visitedBefore');

        if (!hasVisitedBefore) {
            localStorage.setItem('visitedBefore', 'true');
        }
        setShow(!hasVisitedBefore);

    }, []);

    const isFrenchPath = location.pathname.startsWith("/Catalog/fr") || location.pathname.startsWith("/fr");
    return (
        <>
            {isFrenchPath ? (
                <>
                    <div className="btn btn-orange guide" onClick={() => setShow(!show)}>Soutien<img src="/question.png" className="question" alt="question"></img></div>
                    <Modals title="Découvrez Dec'd Out Lookbook: Votre Baguette Magique pour les Lookbooks Personnalisée."
                        show={show}
                        onHide={() => setShow(false)}
                        dialogClassName="guide-modal"
                        size="xl"
                        scrollable={true}
                    >
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <div className="w-75">
                                <p className="text-center">Bienvenue dans Dec'd Out Lookbook, l'outil qui permet aux distributeurs de produits promotionnels comme vous de créer facilement des lookbooks captivants et personnalisés. Que vous soyez un professionnel chevronné ou que vous commenciez tout juste, ce manuel d'utilisation vous guidera étape par étape dans le processus.</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <div className="w-75">
                                <p className="bold">Pour commencer:</p>
                                <p className="text-center circle"><strong>Étape 1:</strong> Choisissez votre(s) lookbook(s)</p>
                                <p className="mt-5 mb-5">Sélectionnez les lookbooks que vous souhaitez personnaliser avec le logo téléchargé. Vous pouvez choisir un ou plusieurs lookbooks à adapter à vos besoins.</p>
                                <p className="mt-3 mb-3">En cliquant sur le bouton d'aperçu, vous pourrez feuilleter les pages d'une version non décorée.</p>
                                <p className="text-center circle mt-5"><strong>Étape 2:</strong> Téléchargez votre logo</p>
                                <p className="mt-5 mb-5">Ensuite, téléchargez le logo de votre client au format PNG ou SVG. Ce logo sera au cœur de votre lookbook personnalisé.</p>
                                <p className="mt-5 mb-5">Pour obtenir les meilleurs résultats, suivez ces astuces rapides lors du téléchargement de votre logo:</p>
                                <p className="mt-3 mb-3"><strong>La Clarté est Essentielle:</strong> Optez pour un logo clair en noir et blanc. Cela nous aide à reproduire des effets du monde réel, tels que la décoration en relief, la gravure au laser et l'impression sérigraphique.</p>
                                <p className="mt-3 mb-3"><strong>Simplicité:</strong> Les logos complexes avec des demi-tons, des motifs ou des ombrages pourraient ne pas fonctionner correctement. Restez sur des designs simples.</p>
                                <p className="mt-3 mb-3"><strong>L'Arrière-Plan Compte:</strong>  Votre logo devrait être sur un fond blanc ou transparent pour une intégration sans heurts.</p>

                                <p className="text-center circle mt-5"><strong>Étape 3:</strong> Entrez votre adresse e-mail</p>
                                <p className="mt-5 mb-5">Fournissez votre adresse e-mail, à laquelle nous enverrons le lookbook personnalisé une fois qu'il sera prêt. <br />
                                    <strong>Soumettre votre demande:</strong></p>
                                <p className="mt-5 mb-5">Après avoir suivi ces étapes, il vous suffit de cliquer sur le bouton 'Envoyer l'e-mail' pour soumettre votre demande. Notre système traitera votre demande rapidement.</p>
                            </div>
                        </div>
                    </Modals >
                </>
            ) : (
                <>
                    <div className="btn btn-orange guide" onClick={() => setShow(!show)}>Help<img src="/question.png" className="question" alt="question"></img></div>
                    <Modals title="Introducing Dec'd Out Lookbook: Your Personalized Lookbook Magic Wand"
                        show={show}
                        onHide={() => setShow(false)}
                        dialogClassName="guide-modal"
                        size="xl"
                        scrollable={true}
                    >
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <div className="w-75">
                                <p className="text-center">Welcome to Dec'd Out Lookbook – the tool that empowers promotional product distributors like you to create captivating, customized lookbook with ease. Whether you're a seasoned pro or just getting started, this user manual will walk you through the process step by step.</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <div className="w-75">
                                <p className="bold">Get Started:</p>
                                <p className="text-center circle"><strong>Step 1:</strong> Choose Your lookbook(s)</p>
                                <p className="mt-5 mb-5">Select the lookbooks you'd like to customize with the uploaded logo. You can choose one or more lookbooks to tailor to your needs.</p>
                                <p className="mt-3 mb-3">Clicking the preview button will allow you to flip through the pages of an undecorated version.</p>
                                <p className="text-center circle mt-5"><strong>Step 2:</strong> Upload your Logo</p>
                                <p className="mt-5 mb-5">Next, upload your client's logo in PNG or SVG format. This logo will be the centerpiece of your customized lookbook.</p>
                                <p className="mt-5 mb-5">For the best results, follow these quick tips when uploading your logo:</p>
                                <p className="mt-3 mb-3"><strong>Clarity is Key:</strong> Opt for a clear, black and white logo. It helps us mimic real-world effects like Deboss Branding, Laser Engraving, and Silk Screen Printing.</p>
                                <p className="mt-3 mb-3"><strong>Keep it Simple:</strong> Complex logos with half-tones, patterns, or shading might not work well. Stick to straightforward designs.</p>
                                <p className="mt-3 mb-3"><strong>Background Matters:</strong> Your logo should be on a white or transparent background for seamless integration.</p>
                                <p className="text-center circle mt-5"><strong>Step 3:</strong> Enter Your Email</p>
                                <p className="mt-5 mb-5">Provide your email address where we'll send the customized lookbook once it's ready. <br />
                                    <strong>Submitting Your Request:</strong></p>
                                <p className="mt-5 mb-5">After completing these steps, simply click the "Send Email" button to submit your request. Our system will process your request swiftly.</p>
                            </div>
                        </div>
                    </Modals >
                </>
            )}

        </>
    )
}

export default Help