import { Toaster } from "react-hot-toast"

const ToasterProvider = () => {
    return <Toaster toastOptions={{
        className: 'defaultToast',
        style: {
            border: '1px solid #F1B24F',
            padding: '16px',
            color: '#000',
        },
    }} />
}

export default ToasterProvider