import React, { useEffect, useState, useRef, useCallback, useContext, useMemo } from "react";
import ReactGA from 'react-ga4';
import toast from 'react-hot-toast';
import ApiLinks from "../api/apis";
import Loader from "../assets/Loading";
import Modals from "../assets/Modals";
import Slider from "./Slider/Slider";
import { useDropzone } from 'react-dropzone'
import LocaleContext from '../context/LocaleContext';
import { useTranslation } from "react-i18next";

import axios from "axios";
import Ratings from "./Ratings";
const hostUrl = ApiLinks.ActiveLink();
let noFormatImg;

const CreateCatalog = () => {
  const { t } = useTranslation()
  const [files, setFiles] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAdvance, setModalAdvance] = useState(false);
  const [selectedCatalogs, setSelectedCatalogs] = useState([]);
  const [modalSelected, setModalSelected] = useState(false)
  const [image, setImage] = useState("");
  const [imageFileActive, setImageFileActive] = useState("");
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [spinnerBrand, setBrandSpinner] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [modalEmail, setModalEmail] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0);
  const [spanIndex, setSpanIndex] = useState(1)
  const [userEmail, setUserEmail] = useState('')
  const [previewCatalog, setPreviewCatalog] = useState(false)
  const [selectedUrl, setSelectedUrl] = useState(null)
  const [validEmail, setValidEmail] = useState(false)
  const [logoType, setLogotype] = useState("square")
  const [fileName, setFileName] = useState("")
  const { locale, setLocale } = useContext(LocaleContext);
  const [colors, setColors] = useState()
  const [selectedColors, setSelectedColors] = useState([]);
  // const [removeBackground, setRemoveBackground] = useState(false);
  const [previewBrandPatch, setPreviewBrandPatch] = useState(false)
  const [imageBrandPatch, setBrandPatchImage] = useState()
  const [catalogFeatures, setCatalogFeatures] = useState("");
  const [chk, setChk] = useState("")
  const [rhk, setRhk] = useState("")
  const [disabledFeedBackBtn, setDisabledFeedBackBtn] = useState(false)





  const handleNextClick = () => {
    setCurrentIndex(currentIndex + 1);
  };
  const handleBackClick = () => {
    setCurrentIndex(currentIndex - 1);
  };
  /*Set the SelectedCatalogs*/
  function Basic() {
    const onDrop = useCallback((acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const selectedFile = acceptedFiles[0];
        setFileName(selectedFile.name);
        if (selectedFile.type === "image/png" || selectedFile.type === "image/svg+xml") {
          setFiles(acceptedFiles);
          setModalVisible(true);
          setSpinner(true);
          sessionStorage.removeItem("processimage");

          var reader = new FileReader();
          reader.readAsDataURL(selectedFile);

          reader.onloadend = function () {
            let base64data = reader.result;
            setImage(base64data)
            setSpinner(true);

            // Check if processing should be skipped
            fetch(hostUrl + `images/process`, {
              method: 'POST',
              headers: {
                'Content-Type': "text/json"
              },
              body: JSON.stringify({
                "fileupload": base64data
              })
            })
              .then(res => res.json())
              .then(data => {
                setImageFileActive(data.hasWhite ? data.upload : base64data);
              })
              .catch((err) => {
                console.log("unable to process image " + err);
                setSpinner(false);
                setImage("")
                alert("Error, please try a new logo.");
              })
              .finally(() => setSpinner(false));
          }
        } else {
          console.error("Invalid file type. Please select a PNG or SVG image.");
        }
      }
    });

    const { getRootProps, getInputProps, open } = useDropzone({
      accept: {
        'image/png': ['.svg', '.png']
      }, maxFiles: 1, onDrop, noClick: true
    });

    // Function to close the modal
    const closeModal = () => {
      sessionStorage.removeItem("processimage");
      if (sessionStorage.getItem("logoType")) {
        setModalVisible(false);

      } else if (selectedCatalogs.includes("Jan24") || selectedCatalogs.includes("Jan24FR")) {
        setModalVisible(true);
        toast(t("Home.brandPatchNext"), { duration: 3700, className: "lookbookselect", icon: "🟠🟥" })
      }
      else {
        setModalVisible(false);
      }

    };
    const handleAdvanceImageProcessingSubmit = (event) => {
      event.preventDefault();
      setModalAdvance(!modalAdvance)
      setSpinner(true)
      const formData = new FormData();
      formData.append("fileupload", image);
      axios.post(`${hostUrl}images/advprocess`, formData)
        .then(response => {
          setColors(response.data.colors)
        })
        .catch(error => {
          console.error('Error:', error);
        }).finally(() => {
          setSpinner(false)
        })
    };
    const handleCheckboxChange = (color, setSelectedColors) => {
      setSelectedColors((prevSelectedColors) => {
        if (prevSelectedColors.includes(color)) {
          return prevSelectedColors.filter((selectedColor) => selectedColor !== color);
        } else {
          // Add color if not selected
          return [...prevSelectedColors, color];
        }
      });
    };
    const openDropzone = () => {
      sessionStorage.removeItem('logoType');
      open(); // This will open the dropzone
    };

    const handleAdvanceSubmit = async (selectedColors) => {
      setImageFileActive("")
      if (!selectedColors || selectedColors.length === 0) {
        setImageFileActive(image)
        setModalAdvance(!modalAdvance);
        return;
      }
      try {
        setModalAdvance(!modalAdvance);
        setSpinner(true);
        const formData = new FormData();
        formData.append('fileupload', image);
        formData.append("removecolors", JSON.stringify(selectedColors));
        const response = await axios.post(`${hostUrl}images/advresults`, formData);

        if (response.status === 200) {
          console.log(response.data.proccessedImg, 'handleAdvanceSubmit')
          console.log('REMOVED COLOR AVANCE SUBMIT')
          setImageFileActive(response.data.proccessedImg);

        } else {
          console.error('API Error:', response.statusText);
        }
      } catch (error) {
        console.error('Axios Error:', error.message);
      } finally {
        setSpinner(false);
        setSelectedColors([])
      }
    };
    const handleOpenBrandPatch = async () => {
      if (catalogFeatures === "brandpatch" || selectedCatalogs.includes('Jan24') || selectedCatalogs.includes('Jan24FR')) {
        setBrandSpinner(true);
        setPreviewBrandPatch(true)
        const formData = new FormData();
        formData.append('fileupload', imageFileActive);
        try {
          const response = await axios.post(`${hostUrl}images/brandpatch`, formData);
          if (response.status === 200) {
            setBrandPatchImage(response.data)
          } else {
            console.error('API Error:', response.statusText);
          }
        } catch (error) {
          console.error('Axios Error:', error.message);
        } finally {
          setBrandSpinner(false);
        }
      } else if (catalogFeatures === "") {
        setModalVisible(false);
      } else {
        setModalVisible(false);
      }

    }
    const closeBrandPatch = () => {
      setPreviewBrandPatch(!previewBrandPatch)
    }
    const closeBrandpatchNext = () => {

      if (sessionStorage.getItem('logoType')) {
        setPreviewBrandPatch(!previewBrandPatch)
      } else {
        toast(t("Home.brandPatchShape"), {
          duration: 3000,
          // Custom Icon
          icon: '👏',
        });

      }
    }
    const approveBrandPatch = () => {
      setPreviewBrandPatch(!previewBrandPatch)
      closeModal()

    }

    const closeManualModal = () => {
      if (selectedColors.length < 1) {
        toast(t("Home.colorsToRemoved"), {
          duration: 3000,
          // Custom Icon
          icon: '👏',
        });
      } else {
        setModalAdvance(!modalAdvance)
      }

    }

    const handleBrandPatchShape = useCallback((type) => {
      sessionStorage.setItem('logoType', type);
      // Force a re-render
      forceUpdate();
    }, []);

    // Dummy forceUpdate function
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);


    function ShowSelectedImage({ newimageone, color }) {
      return (
        <div className="preview-min-height preview-image-background">
          <img src={newimageone} className={`img card-custom ${color ? "" : "filter-0"} image-preview mt-3 mb-3 `} />
        </div>
      );
    }
    function ShowSelectedImage2({ newimagetwo }) {
      return (
        spinner ? <Loader.BasicLoader class="mw-50" /> : <>
          <div className="preview-image-background mt-3">
            <img src={newimagetwo} className="img image-preview" alt="preview" />
          </div>
        </>
      );
    }
    function ShowSelectedImage3({ newimagethree }) {
      return (
        spinner ? (
          <Loader.BasicLoader class="mw-50" />
        ) : (
          <div className="preview-image-background mt-3">
            <img src={newimagethree} className="img image-preview filter-0 three" alt="preview" />
          </div>
        )
      );
    }
    const thumbs = files.map((file) => (
      <div key={file.name}>
        <div id="myModal" className={`modal ${modalVisible ? 'open' : ''}`} >
          <div className="modal-content preview">
            <span className="close" onClick={closeModal}>&times;</span>
            <div className="scrollable d-flex align-items-start upload-modal-sm">
              <div className="w-70">
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h2 className="text-center mb-2"></h2>
                  <div className="d-flex align-items-center justify-content-between gap-5 flex-column flex-xl-row">
                    <div className="w-50">
                      <span className=" fw-bold">{t("Home.original")}</span>
                      <ShowSelectedImage2 newimagetwo={image} />
                    </div>
                    <div className="w-50">
                      <span className=" fw-bold">{t("Home.OneConversion")}</span>
                      <ShowSelectedImage3 newimagethree={imageFileActive} />
                    </div>
                  </div>

                  <div className="w-full d-flex justify-content-around align-items-center gap-5 mt-4 flex-column flex-xl-row">
                    <div className="upload-btn bg-gray" onClick={openDropzone} >{t("Home.reupload")}</div>
                    <div className="upload-btn bg-yellow" onClick={handleAdvanceImageProcessingSubmit}>{t("Home.advanceImage")}</div>
                    <div className="upload-btn bg-yellow" onClick={handleOpenBrandPatch}>{t("Home.approve")}</div>
                  </div>
                </div>
              </div>
              <div className="line-container">
                <div className="vl"></div>
              </div>
              <div className="w-30 d-flex justify-content-center align-items-center">
                <div className="info-desc mt-2">
                  <h3 className="gray"><strong>{t("Home.forBestResults")}</strong></h3>
                  <p className="mt-3 mb-3"><strong className="gray semi-title">{t("Home.background")}</strong> {t("Home.yourLogo")}</p>

                  <p className="mt-3 mb-3">
                    <strong className="gray semi-title">{t("Home.clarity")}</strong>{t("Home.optez")}</p>
                  <p className="mt-3 mb-3">
                    <strong className="gray semi-title">{t("Home.simple")}</strong>{t("Home.complex")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Manual Adjustment*/}
        <div id="advanceImage" className={`modal ${modalAdvance ? "show" : ""}`}>
          <div className="modal-content preview advance-image">
            <span className="close" onClick={closeManualModal}>&times;</span>
            <section>
              <div className="scrollable d-flex justify-content-center">
                <div className="w-70">
                  <div className="d-flex justify-content-evenly align-items-center flex-column gap-2">
                    <h2 className="text-center mb-2">{t("Home.selectColors")}</h2>
                    <div className="preview-image-background">
                      <img className="manual-image-adjustment" src={image} alt="uploaded image" />
                    </div>
                    <div className="w-full d-flex justify-content-around align-items-center gap-5 mt-5 flex-wrap">
                      {spinner ? <p>{t("Home.analyzeColors")}</p> : (
                        <>
                          {colors?.map((color, index) => (
                            <div className="form-check" key={index + 1}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`colorCheckbox${index}`}
                                checked={selectedColors?.includes(color)}
                                onChange={() => handleCheckboxChange(color, setSelectedColors)}
                              />
                              <label htmlFor={`colorCheckbox${index}`} className="d-flex">
                                <div className="color-box" style={{ backgroundColor: `rgba(${color.join(', ')})` }}>
                                  {/*add content inside the color-box if needed */}
                                </div>
                              </label>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                    <div className="w-100 d-flex justify-content-center align-items-center gap-2 mt-4">
                      <div className={`upload-btn sm bg-gray`} onClick={closeManualModal}>Back</div>
                      <div className={`upload-btn bg-yellow sm`} onClick={() => handleAdvanceSubmit(selectedColors)}>{t("Home.removeColors")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/*Manual Adjustment*/}
        {/*Next Step Preview for BrandPatch*/}
        <Modals
          size="lg"
          show={previewBrandPatch}
          onHide={closeBrandpatchNext}
          className="modal-brandpatch"
          title={t("Home.greatNews")}
        >
          <div className="d-flex gap-2 mt-2 mb-2">
            {spinnerBrand ?
              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <Loader.BasicLoader class="mw-50 mb-2" /><p>{t("Home.creatingBrand")}</p>
              </div> : <>
                <div className="image-b-container" onClick={() => handleBrandPatchShape("circle")}>
                  <img src={imageBrandPatch?.circlePatch} className={`image-brandpatch `} alt="circle-shape"
                  />
                  <div className={`check-mark brand ${sessionStorage.getItem("logoType") === "circle" ? "selected" : ""}`}>
                    <span className="check">&#10003;</span>
                  </div>
                </div>

                <div className="image-b-container" onClick={() => handleBrandPatchShape("square")} >
                  <img src={imageBrandPatch?.squarePatch} className={`image-brandpatch`} alt="square-shape"

                  />
                  <div className={`check-mark brand  ${sessionStorage.getItem("logoType") === "square" ? "selected" : ""}`}>
                    <span className="check">&#10003;</span>
                  </div>
                </div>

                <div className="image-b-container" onClick={() => handleBrandPatchShape("rectangle")}>
                  <img
                    src={imageBrandPatch?.rectanglePatch}
                    className={`rectangle-brandpatch`}
                    alt="rectangle-shape"

                  />
                  <div className={`check-mark brand ${sessionStorage.getItem("logoType") === "rectangle" ? "selected" : ""}`}>
                    <span className="check">&#10003;</span>
                  </div>
                </div>


              </>
            }
          </div>
          <div className="w-full d-flex justify-content-around align-items-center gap-5 mt-4 flex-column flex-xl-row">
            <div className="upload-btn bg-gray" onClick={closeBrandPatch} >{t("Home.back")}</div>
            <div className={`upload-btn ${sessionStorage.getItem("logoType") ? "bg-yellow clickable" : "bg-gray noclick"}`} onClick={approveBrandPatch}>{t("Home.approveBrand")}</div>
          </div>
        </Modals>
        {/*Next Step Preview for BrandPatch*/}
      </div>
    ));

    useEffect(() => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [])

    return (
      <section>
        <div className="d-flex justify-content-center">
          <div {...getRootProps({})} className="d-flex flex-column justify-content-center align-items-center p-4" onClick={openDropzone}>
            <input {...getInputProps()} name="fileupload" className="inputt" />
            <img src="/images/image-upload.png" alt="uploaded-img" className={`${files.length === 1 ? "hide" : ""}`} />
            <div className={`text-center ${files.length === 1 ? "hide" : ""}`}  ><strong>{t("Home.click")}</strong> {t("Home.or")} <strong>{t("Home.drag")}</strong> <br /> <span className="text-muted">{t("Home.artwork")}</span> </div>
            <div className={`text-center mt-2 text-muted ${files.length === 1 ? "hide" : ""}`}  >{t("Home.formats")}</div>
            <div className="d-flex justify-content-center selected-image flex-column justify-content-center align-items-center">
              <div className={`${files.length === 0 ? "hide " : "d-flex"} align-items-center justify-content-between gap-5`}>
                <div className="w-50">
                  <span className=" fw-bold">{t("Home.original")}</span>
                  <div className="margin-auto">
                    <ShowSelectedImage newimageone={image} color={true} />
                  </div>
                </div>
                <div className="w-50">
                  <span className=" fw-bold">{t("Home.OneConversion")}</span>
                  <div className="margin-auto">
                    <ShowSelectedImage newimageone={imageFileActive} color={false} />
                  </div>
                </div>
              </div>
              {sessionStorage.getItem("logoType") ? <>
                <div className={` ${files.length === 0 ? "hide " : "d-flex"}`}>
                  <p className="text-muted mr-1">{t("Home.logoType")}</p>
                  {/* {logoType.charAt(0).toUpperCase() + logoType.slice(1)} */}
                  <span className={`fw-bold`}>{sessionStorage.getItem("logoType")}</span>
                </div>
              </> : null}

            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="position-relative">{thumbs}</div>
        </div>
      </section>
    );
  }



  function GetCatalogs() {
    let statwa = [];
    if (sessionStorage.getItem("catalogs")) {
      statwa = JSON.parse(sessionStorage.getItem("catalogs"));
    }
    const [catalogs, setCatalogs] = useState(statwa);
    const { locale } = useContext(LocaleContext);


    //useCallBack function 
    const handleCatalogSelection = useCallback((catalogName, feature) => {
      setCatalogFeatures(feature)
      if (sessionStorage.getItem("logoType")) {
        console.log('clear')
        sessionStorage.removeItem("logoType")
      }
      if (selectedCatalogs.includes(catalogName)) {
        // If the catalog is already selected, remove it from the selectedCatalogs array
        setSelectedCatalogs(selectedCatalogs.filter((name) => name !== catalogName));
      } else {
        if (selectedCatalogs.length < 3) {
          // Only allow selection if there are fewer than 3 selected catalogs
          const newSelectedCatalogs = [...selectedCatalogs, catalogName];
          setSelectedCatalogs(newSelectedCatalogs);
        } else {
          setModalSelected(true)
        }
      }
    }, []);

    useEffect(() => {
      const fetchData = async () => {
        try {
          let regionCode;
          switch (locale) {
            case "fr":
              regionCode = "ca/fr";
              break;
            case "us":
              regionCode = "us/en";
              break;
            case "en":
              regionCode = "ca/en";
              break;
            default:
              regionCode = "us/en"; // Default URL
          }
          const url = hostUrl + `lookBook/r-l/${regionCode}`;
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          sessionStorage.setItem("catalogs", JSON.stringify(data));
          setCatalogs(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();

    }, [locale]);

    const handlePreview = (catalogUrl) => {
      console.log(catalogUrl, 'check')
      setSelectedUrl(catalogUrl)
      setPreviewCatalog(() => !previewCatalog)
    }

    return (
      <>
        <div className="d-flex flex-wrap flex-row gap-5 lookbook-list">
          {
            catalogs?.map((catalog) => (
              <div key={catalog.name} className={`card-custom ${selectedCatalogs.includes(catalog.name) ? "selected" : ""}  `}
              >
                <div
                  style={{ cursor: 'pointer' }}
                  className={`position-relative`}
                  onClick={() => handleCatalogSelection(catalog.name, catalog.feature)} // Add event handler here
                >
                  <img className="card-img-top max-height" src={"/images/lookbooks/" + catalog.name + ".jpg"} alt={catalog.name} />
                  <div className={`check-mark ${selectedCatalogs.includes(catalog.name) ? "selected" : ""}`}>
                    <span className="check">&#10003;</span>
                  </div>
                </div>
                <div
                  style={{ cursor: 'pointer' }}
                >
                  <div className="d-flex align-items-center justify-content-center position-relative mt-1">
                    <h5 className="card-title text-center">{catalog.display}</h5>
                    {(catalog.new === 1) ? (<p className="new">{t("Home.new")}</p>) : null}
                  </div>
                  <div className="preview-catalog mt-2" onClick={() => handlePreview(catalog.name)}>{t("Home.previewCatalog")}</div>
                </div>
              </div>
            ))
          }

        </div>
        <Modals
          size="lg"
          show={previewCatalog}
          onHide={() => setPreviewCatalog(false)}
          className="modal-iframe"
        >
          {selectedUrl === "Gifting24US" && (
            <iframe className="preview-iframe" src="https://gifting-2024.vercel.app/" title={selectedUrl}></iframe>
          )}
          {selectedUrl === "Gifting24FR" && (
            <iframe className="preview-iframe" src="https://giftingfr-2024.vercel.app/" title={selectedUrl}></iframe>
          )}
          {selectedUrl === "Gifting24CA" && (
            <iframe className="preview-iframe" src="https://gifting-2024.vercel.app/" title={selectedUrl}></iframe>
          )}
          {selectedUrl === "Gifting23US" && (
            <iframe className="preview-iframe" src="https://gift-usa.vercel.app/" title={selectedUrl}></iframe>
          )}
          {selectedUrl === "Gifting23CA" && (
            <iframe className="preview-iframe" src="https://gift-canada.vercel.app/" title={selectedUrl}></iframe>
          )}
          {selectedUrl === "Ora23" && (
            <iframe className="preview-iframe" src="https://gifting-ora.vercel.app/" title={selectedUrl}></iframe>
          )}
          {selectedUrl === "Ora23FR" && (
            <iframe className="preview-iframe" src="https://gifting-ora-french.vercel.app/" title={selectedUrl}></iframe>
          )}
          {selectedUrl === "Gifting23FR" && (
            <iframe className="preview-iframe" src="https://gifting-french.vercel.app/" title={selectedUrl}></iframe>
          )}
          {selectedUrl === "Jan24" && (
            <iframe className="preview-iframe" src="https://jan-2024-us.vercel.app/" title={selectedUrl}></iframe>
          )}
          {selectedUrl === "Jan24FR" && (
            <iframe className="preview-iframe" src="https://jan-2024-fr.vercel.app/" title={selectedUrl}></iframe>
          )}
          {selectedUrl === "TrendReportSetup24" && (
            <iframe className="preview-iframe" src="https://trendreport-2024-us.vercel.app/" title={selectedUrl}></iframe>
          )}
          {selectedUrl === "TrendReportSetup24FR" && (
            <iframe className="preview-iframe" src="https://trendreport-2024-fr.vercel.app/" title={selectedUrl}></iframe>
          )}
        </Modals>
      </>
    );
  }

  const BacktoZero = () => {
    setTimeout(() => window.location.reload(true), 1);
    setCurrentIndex(0)
  }
  const handleRatingSubmit = async ({ rating, message }) => {
    try {
      const formData = new FormData();
      formData.append('chk', chk);
      formData.append('rhk', rhk);
      formData.append('rating', rating);
      formData.append('comment', message);
      const apiUrl = `${hostUrl}savecatalog/rating`;
      const method = 'POST';
      // Fetch options
      const options = {
        method: method,
        body: formData,
      };
      const response = await fetch(apiUrl, options);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('API IN GIVE FEEDBACK:', data);
      if (data.message === "success") {
        toast(t("Home.successFeedback"))
        setDisabledFeedBackBtn(true)
      }
      // Handle any additional logic based on the API response
    } catch (error) {
      console.error('Error during API call:', error);
      // Handle the error appropriately
    }
  };
  const handleEmailInputChange = (event) => {
    setUserEmail(event.target.value);
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,}$/;

  useEffect(() => {
    const isEmailValid = emailRegex.test(userEmail);
    setValidEmail(isEmailValid);

  }, [userEmail, emailRegex]);

  const onSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: "Form Submission",
      action: "Catalog/Lookbook Request",
      label: "Submitted with Email and File Upload",
      value: 1,
    });

    try {
      if (!validEmail) {
        setModalEmail(true);
      } else if (files.length > 0) {
        setLoading(true);
        fetch(hostUrl + `savecatalog/save`, {
          method: 'POST',
          headers: {
            'Content-Type': "text/json"
          },
          body: JSON.stringify({
            "email": userEmail,
            "lookbooks": selectedCatalogs,
            "fileupload": imageFileActive,
            "lang": locale === "us" ? "EN" : locale === "ca" ? "EN" : locale === "fr" ? "FR" : locale,
            "filename": fileName,
            "logoType": sessionStorage.getItem('logoType') || logoType
          })
        })
          .then(res => {
            if (!res.ok) {
              throw new Error(res.statusText);
            }
            return res.json();
          })
          .then(data => {
            console.log('Response data:', data);
            setChk(data.chk)
            setRhk(data.rhk)
            setLoading(false);
            setSubmitted(true);
            handleNextClick();
            // Use data as needed
          })
          .catch(err => {
            console.error("API error:", err);
            setLoading(false);
            // Display user-friendly error message
            if (err.toString() === "Error: Request Entity Too Large") {
              alert("The file you are trying to upload is too large. Please upload a smaller file.");
              console.log('File size is too large')
            } else {
              console.log("An error occurred while submitting the request. Please try again later.");
            }
          });
      } else {
        // Handle the case where files are missing
        console.log('No Files uploaded')
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      setLoading(false);
      // Display user-friendly error message
      console.log("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Slider currentIndex={currentIndex} spanIndex={spanIndex} />
        </div>
        <form onSubmit={onSubmit}>
          {/**STEP ONE UPLOAD IMAGE*/}
          {currentIndex === 0 && (
            <>
              <div className=" p-2 mx-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="fw-bold">
                    {t("Help.step-one")}: {t("Home.selectCatalog")}
                  </h5>
                  <div className={`next-btn ${selectedCatalogs.length > 0 ? "selected" : "not-selected"}`} onClick={handleNextClick}>{t("Home.next")}</div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-start mt-5 mb-5">
                <GetCatalogs />
              </div>

            </>
          )}

          {/**STEP TWO UPLOAD CATALOGS */}
          {currentIndex === 1 && (
            <>
              <h5 className="fw-bold">
                {t("Help.step-two")}: {t('Help.uploadLogo')}
              </h5>
              <div className="upload p-2 d-flex flex-column justify-content-center align-items-center">
                <div className="box border ">
                  <Basic />
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center mt-5">
                <div className="back-btn" onClick={handleBackClick}>{t("Home.back")}</div>
                {files.length === 1 ? (
                  <div className="next-btn" onClick={handleNextClick}>{t("Home.next")}</div>
                ) : null}
              </div>
            </>
          )}
          {/**STEP THREE SUBMIT */}
          {currentIndex === 2 && (
            <>
              <h5 className="fw-bold">
                {t("Help.step-three")}: {t("Help.enterEmail")}
              </h5>
              <div>
                <div className="d-flex justify-content-center align-items-center flex-column h-500 send-form-input">
                  <div className="d-flex border-input mb-2">
                    <input placeholder={t("Home.exampleEmail")} type="email" name="email" className="input-email" required value={userEmail} onChange={handleEmailInputChange}></input>
                    <button type="submit" className="btn btn-orange send-email" disabled={loading}>{loading ? t("Home.sending") : t("Home.submitRequest")}</button>
                  </div>
                  <div className="w-30 text-center mt-3">
                    <span>{t("Home.please")}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-center mt-5">
                  <div className="back-btn" onClick={handleBackClick}>{t("Home.back")}</div>
                </div>
              </div>
            </>
          )}
          {currentIndex === 3 && (
            <>
              <div className="position-relative d-flex justify-content-evenly  align-items-center gap-5">
                <div className="thank-you d-flex flex-column justify-content-center align-items-center w-40">
                  <h5 className="text-center fw-bold gray">
                    {t("Home.thankyou")}
                  </h5>
                  <div className="info-label mt-1 gray text-center">{t("Home.tyText")}
                    <strong className="yellow"> {userEmail} </strong> {t("Home.tyWithin")}
                  </div>
                  <div className="next-btn mt-3 sm ratings" onClick={BacktoZero}>{t("Home.newRequest")}</div>
                  <Ratings onRatingSubmit={handleRatingSubmit} disabledFeedBackBtn={disabledFeedBackBtn} setCurrentIndex={setCurrentIndex} />
                </div>

              </div>
            </>
          )}

        </form>
      </div>
      <Modals title="Oops!"
        size="lg"
        show={modalShow}
        onHide={() => setModalShow(!modalShow)}
      >
        <p>{t("Home.uploadImage")}</p>
      </Modals>
      <Modals title="Oops!"
        size="lg"
        show={modalEmail}
        onHide={() => setModalEmail(!modalEmail)}
      >
        <p>{t("Home.validEmail")}</p>
      </Modals>
      <Modals title="Oops!"
        size="lg"
        show={modalSelected}
        onHide={() => setModalSelected(!modalSelected)}
      >
        <p>{t("Home.validSelect")}</p>
      </Modals>
    </>

  )
};

export default CreateCatalog;