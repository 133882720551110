import React from 'react';
import useConversionProgressStore from '../store/useConversionProgressStore';
import { useTranslation } from "react-i18next";


const ConversionProgress = () => {
    const { isConverting, conversionProgress } = useConversionProgressStore();
    const { t } = useTranslation();
    if (!isConverting) return null;

    return (
        <div className="d-flex justify-content-center align-items-center position-fixed w-100 h-100 bg-dark bg-opacity-50 top-0 start-0">
            <div className="bg-white rounded shadow-lg p-4 w-50 max-w-md" style={{ maxHeight: '15vh' }}>
                <p className="text-center text-secondary fw-medium mb-3">
                    {t("Home.holdTight")} <span className="fw-bold">{conversionProgress}%</span>
                </p>
                <div className="progress h-progress">
                    <div
                        className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        style={{ width: `${conversionProgress}%` }}
                        aria-valuenow={conversionProgress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div>
                <div className="text-center mt-3">
                    <div className="spinner-border text-warning" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConversionProgress;
