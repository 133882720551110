import Spinner from 'react-bootstrap/Spinner';


function BasicLoader() {
  return (
    <div className='d-flex justify-content-center align-items-center'>
      <span className="loader"></span>
    </div>
  );
}

function PageSpinner() {
  return (
    <div className='container loading-wp'>
      <span className="loader"></span>
    </div>
  )
}

export default {
  BasicLoader,
  PageSpinner
};