import React, { useState } from "react";
import Modals from "./Modals";
import ReactPlayer from 'react-player'
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Tutorial = () => {
    const { t } = useTranslation()
    const [show, setShow] = useState(false)
    const location = useLocation();
    const isFrenchPath = location.pathname.startsWith("/Catalog/fr") || location.pathname.startsWith("/fr");
    return (
        <>
            {isFrenchPath ? (
                <>
                    <div className="btn tutorial bg-yellow" onClick={() => setShow(!show)}>Voir le tutoriel</div>
                    <Modals title="Présentation du Lookbook Dec'd Out : votre baguette magique Lookbook personnalisée"
                        show={show}
                        onHide={() => setShow(false)}
                        dialogClassName="tutorial-modal"
                        size="xl"
                        scrollable={true}
                    >
                        <ReactPlayer url='https://www.youtube.com/watch?v=4-3zXYzghek' controls={true} width={900} height={550} />
                    </Modals >
                </>
            ) : (
                <>
                    <div className="btn tutorial bg-yellow" onClick={() => setShow(!show)}>Watch the Tutorial</div>
                    <Modals title="Introducing Dec'd Out Lookbook: Your Personalized Lookbook Magic Wand"
                        show={show}
                        onHide={() => setShow(false)}
                        dialogClassName="tutorial-modal"
                        size="xl"
                        scrollable={true}

                    >
                        <ReactPlayer url='https://www.youtube.com/watch?v=wqLirdWkueE' controls={true} width={900} height={550} />
                    </Modals >
                </>
            )
            }
        </>
    )
}

export default Tutorial