
import Modal from 'react-bootstrap/Modal';


function Modals(props) {
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={props.className}
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm" className='orange'>
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
            >{props.children}</Modal.Body>
        </Modal >
    );
}

export default Modals