import { create } from 'zustand';

const useLocaleStore = create((set) => ({
    // Initialize with stored value or default to 'us'
    locale: localStorage.getItem('locale') || 'us',

    setLocale: (newLocale) => {
        // Save to localStorage when updating
        localStorage.setItem('locale', newLocale);
        set(() => ({ locale: newLocale }));
    },
}));

export default useLocaleStore;
