import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";


const RatingsModal = ({ onRatingSubmit, disabledFeedBackBtn, setCurrentIndex }) => {
    const { t } = useTranslation()
    const [showModal, setShowModal] = useState(false);
    const [rating, setRating] = useState(0);
    const [message, setMessage] = useState('');

    const handleStarClick = (selectedRating) => {
        setRating(selectedRating);
    };

    const handleTextareaChange = (event) => {
        setMessage(event.target.value);
    };

    const submitRating = () => {
        // Call the parent component's callback function with the rating and message
        onRatingSubmit({ rating, message });
        setShowModal(false);

        setTimeout(() => {
            window.location.reload(true)
            setCurrentIndex(0);
        }, 3000); // Delay of 3 second (adjust as needed)
    };

    useEffect(() => {
        // Show the modal after 5 seconds (adjust the timing as needed)
        const timeoutId = setTimeout(() => {
            setShowModal(true);
        }, 1000);

        // Clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, []);

    return (


        <div className="ratings-container">
            <h2 className='fw-bold mb-2'>{t("Home.feedBack")}</h2>
            <p>{t("Home.howdid")}</p>
            <p>{t("Home.pretty")}</p>
            <div id="rating-stars" className="rating-stars">
                {[1, 2, 3, 4, 5].map((star) => (
                    <span
                        key={star}
                        className={`star ${star <= rating ? 'selected' : ''}`}
                        onClick={() => handleStarClick(star)}
                    ></span>
                ))}
            </div>
            <p className='mt-2'>{t("Home.needMore")}</p>
            <textarea
                id="message"
                placeholder=""
                value={disabledFeedBackBtn ? "" : message}
                onChange={handleTextareaChange}
            ></textarea>
            <div className='d-flex justify-content-center align-items-center'>
                {disabledFeedBackBtn ? (<p>{t("Home.thankyouSubmit")}</p>) : (<div className={`next-btn mt-3 sm ratings ${disabledFeedBackBtn ? "disabled" : ""}`} onClick={submitRating}>{t("Home.submitFeedback")}</div>)}

            </div>
        </div>


    );
};

export default RatingsModal;