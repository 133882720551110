
import React from 'react';
import { useTranslation } from "react-i18next";
const NotFoundPage = () => {
    const { t } = useTranslation()
    return (
        <div className='container'>
            <div className='d-flex justify-content-center align-items-center flex-column mt-5'>
                <div className='not-found mb-3'>404</div>
                <h1>{t("Home.oops")}</h1>
                <p>{t("Home.notWorry")}</p>
                <a href='/' className='next-btn mt-3 back'>{t("Home.backHome")}</a>
            </div>
        </div>
    );
};

export default NotFoundPage;