import React, { useEffect, useState } from 'react';
import ApiLinks from '../api/apis'

const Display = ({ title }) => {
    const [displayText, setDisplayText] = useState('');

    useEffect(() => {
        const apiUrl = ApiLinks.ActiveLink() + `lookbook/${title}`;


        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((responseData) => {
                if (responseData && responseData.display) {
                    setDisplayText(responseData.display);
                } else {
                    setDisplayText('Display data not available');
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setDisplayText('Error fetching data');
            });
    }, [title]);
    return (
        <div>
            <h3>{displayText}</h3 >
        </div>
    );
};

export default Display;